export default class RazorPayUtils {
    static KEY_ID = "rzp_test_fyyFF9T1J3G0E9";
    static RAZORPAY_PUBLIC_KEY = "jyXVKgQMnI3Q0Uau68eLGXK3"
    // static KEY_ID = "rzp_live_5dsWJO6EQJhjw2"
    // static RAZORPAY_PUBLIC_KEY = "FkioArPLTS5xASEWmeyp3dKs"
    static PROVIDER = "razorpay"
    static MANOSHANTI_API_KEY = "rzp_live_8XZqXjPRreyNIb"
    static MANOSHANTI_API_SECRET = "4Cz8fK7lIQUgMGpuNqPeFTtr"
    static MANOSHANTI_WEBHOOK_SECRET = "b3i2HwK5TWFr9G5Lw6EaBdow"

    static PAYMENT_CONFIGS =
        {
        "82c396f751084cbbbc1d13ccc789f100" : {  // Hubli
            "apiKey": this.MANOSHANTI_API_KEY,
            "apiSecret": this.MANOSHANTI_API_SECRET,
            "webhookSecret": this.MANOSHANTI_WEBHOOK_SECRET
        },
        "f5c0d62cf3a64f9da22d503d46693a9b": {  // Pune
            "apiKey": this.MANOSHANTI_API_KEY,
            "apiSecret": this.MANOSHANTI_API_SECRET,
            "webhookSecret": this.MANOSHANTI_WEBHOOK_SECRET
        },
        "578d457c4e4845349f3c7bc5b9b716f2": {  // Bengaluru
            "apiKey": this.MANOSHANTI_API_KEY,
            "apiSecret": this.MANOSHANTI_API_SECRET,
            "webhookSecret": this.MANOSHANTI_WEBHOOK_SECRET
        },
        "85a27dd5f48e4d758348cb6eaae09539": {  // Dharwad
            "apiKey": this.MANOSHANTI_API_KEY,
            "apiSecret": this.MANOSHANTI_API_SECRET,
            "webhookSecret": this.MANOSHANTI_WEBHOOK_SECRET
        },
        "fcd97d6b312e47b29465ba031ad10bb2": {  // Dharwad
            "apiKey": this.MANOSHANTI_API_KEY,
            "apiSecret": this.MANOSHANTI_API_SECRET,
            "webhookSecret": this.MANOSHANTI_WEBHOOK_SECRET
        }
    }
    
}

