import moment from "moment";
import _ from 'lodash';

export const getApptTimeSlots = (date, configs, slotInterval = 15) => {
    let slots = []
    // if (modeOfAppointment === 'In-person') {
    let currentDay = moment(date).format('dddd');
    const currentDayConfig = _.filter(configs, ['day', currentDay]);
    currentDayConfig?.forEach((config) => {
        let start = moment(moment(date).format('dd-mm-yyyy') + ' ' + config['start'], 'dd-mm-yyyy HH:mm');
        let end = moment(moment(date).format('dd-mm-yyyy') + ' ' + config['end'], 'dd-mm-yyyy HH:mm')
        slots = [...slots, ...(generateSlots(start, end, slotInterval))]
    });
    // }
    // else {
    //     let day = moment(date).format('ddd');
    //     let slotConfigs = configs[day]
    //     if (slotConfigs['amStart'] && slotConfigs['amEnd']) {
    //         let start = moment(moment(date).format('dd-mm-yyyy') + ' ' + slotConfigs['amStart'], 'dd-mm-yyyy HH:mm');
    //         let end = moment(moment(date).format('dd-mm-yyyy') + ' ' + slotConfigs['amEnd'], 'dd-mm-yyyy HH:mm')
    //         slots['morning'] = generateSlots(start, end, slotInterval);
    //     }
    //     if (slotConfigs['pmStart'] && slotConfigs['pmEnd']) {
    //         let start = moment(moment(date).format('dd-mm-yyyy') + ' ' + slotConfigs['pmStart'], 'dd-mm-yyyy HH:mm');
    //         let end = moment(moment(date).format('dd-mm-yyyy') + ' ' + slotConfigs['pmEnd'], 'dd-mm-yyyy HH:mm')
    //         slots['evening'] = generateSlots(start, end, slotInterval);
    //     }
    // }
    return slots

}

const generateSlots = (start, end, slotInterval) => {
    start = moment(start);
    end = moment(end);
    const timeSlots = [];
    while (start < end && start?.add(slotInterval, 'minutes').isSameOrBefore(moment(end, 'HH:mm'))) {
        const timeSlot = start.format('hh:mm A');
        start.add(slotInterval, 'minutes');
        timeSlots.push(timeSlot);
    }
    return timeSlots;
}


export const getUsername = (obj) => {
    let name = obj?.firstName ? obj?.firstName : '';
    name += obj?.lastName ? ' ' + obj?.lastName : '';
    if (obj?.prefix)
        name = obj.prefix + ' ' + name;
    return name;
}

export const getUserObject = (userObj) => {
    let name = getUsername(userObj);
    return {
        'userId': userObj.userId,
        'name': name,
        'prefix': userObj?.prefix ?? '',
        'firstName': userObj?.firstName ?? '',
        'lastName': userObj?.lastName ? ' ' + userObj?.lastName : '',
        'designation': userObj?.designation ?? '',
        'pdEmail1': userObj?.pdEmail1 ?? '',
        'pdPhone1': userObj?.pdPhone1 ?? '',
        'medicalCouncilName1': userObj?.medicalCouncilName1 ?? '',
        'dob': userObj?.dob ?? '',
        'gender': userObj?.gender ?? '',
        'qualification': userObj?.qualification ?? '',
        'medicalCouncilName2': userObj?.medicalCouncilName2,
        'userName': userObj?.userName,
        'pdPhone2': userObj?.pdPhone2,
        'pdEmail2': userObj?.pdEmail2,
        'heId': userObj?.heId
    };
}
export const CONSENT = (
    <>
        <p>1. I understand that I am seeking consultation/psychotherapy for evaluation and treatment of psychological issues.</p>
        <p>2. Psychological issues are complex and requires a few sessions for a thorough understanding of the difficulties. The evaluation will involve clinical interview, psychological tests and in some cases medical examination and investigations may be necessary. In most cases one session is not enough to achieve any outcome.</p>
        <p>3. I understand that I need to provide all information about past or current medical or psychiatric illness and any treatment that I may have received for the same in the past or which I am currently taking. I understand that I need to share information regarding any allergies or reactions to drugs I may have had.</p>
        <p>4. Evaluation also requires us to talk to your companion (relative/friend) who knows you and has observed you closely for a reasonable period of time.</p>
        <p>5. Information shared in consultation/therapy session will be maintained confidential and not shared with others without your consent. In some situations confidentiality may need to be breached 1) Risk of harm to self or others 2) When legally mandated (provision of the POCSO act, medico legal cases, court cases).</p>
        <p>6. I understand that I am free to ask questions and obtain clarifications regarding my treatment. I am free to voluntarily withdraw from treatment at any point of time at my own risk.</p>
        <p>7. I understand that psychotherapy requires that I discuss my problems and difficulties with a psychotherapist, who will endeavour to provide a supportive, empathic environment and listen attentively. The psychotherapist may pay particular attention to my feelings, thought patterns, and ways of interacting in the world and may point these out to me so that I may gain increased understanding and awareness of how events in my life are impacting on me. The purpose of psychotherapy is not to offer advice or solutions to the problems that I am confronting, but to assist me to come to the best decisions and solutions for my particular situation.</p>
        <p>8. All clients are seen by appointment. If I am unable to keep an appointment I understand I need to reschedule 24 hours in advance. In case you have missed the appointment without rescheduling the charges paid cannot be refunded or adjusted for the next session. You will need to book a fresh appointment making the payment again. </p>
        <p>9. Online sessions are conducted as per the telemedicine guidelines 2020 issued by the MOHFW, Govt. of India.</p>
        <p>10. Online consultations/therapy though it is a convenient way of seeking medical support, it does not substitute face-to-face support in its entirety.</p>
        <p>11. Online consultation cannot be used for the purpose of medical/psychiatric emergencies. In case of any medical / psychiatric emergency, you will need to visit a local psychiatrist or physician. Under no circumstances, emergency-based online consultation shall be entertained.</p>
        <p>12. It is up to the discretion of the doctor/psychologist to ascertain whether online consultation/therapy can be done. If they feel that for any reason online consultation/therapy is not preferred in your case they may terminate the session after informing you the reason and ask you to seek an in person consultation. </p>
        <p>13. No medical certificates can be provided following online consultations. You will need to visit a doctor in person for evaluation and certification. </p>
        <p>14. It is your responsibility to ensure that you have good internet connectivity during the session. We are not responsible for failure of sessions due to technical difficulties at your end and are unable to provide a replacement appointment for such failed sessions. However, if the technical failure is at the end of the doctor/therapist a replacement session will be arranged. </p>
        <p>15. If medications are prescribed by the doctor after the consultation, an e-copy of the prescription will be e-mailed to you. For any clarification on the prescription you may contact the clinic number of the doctor during working hours. In case of any intolerance pertaining to prescription and side effect of medications, please stop the medications and consult your local doctor immediately. </p>
        <p>16. Upon booking the appointment, you will receive an email with link for the video consultation/therapy on the email id you have registered on the portal. The link should not be shared with anybody and should be used only by the person in whose name the appointment has been booked. </p>
        <p>17. The online sessions may be recorded by us for the purpose of documentation. Under no circumstance should the client record audio or video of the sessions without consent of the doctor/therapist. </p>
        <p>18. Information obtained in the clinic may be used for research purposes in scientific publications and meetings and I give my consent for the same. I understand that complete anonymity and confidentiality of information will be maintained.</p>
        <p>19. I agree to be contacted by the clinic by telephone or email to communicate information regarding my appointments or any information relevant to my treatment. </p>
        <p>20. I agree that I shall not contact my doctor/therapist on personal numbers, social media platforms and outside working hours. </p>
        <p>21. I also agree that it is a possibility that after the first couple of meetings, my therapist will assess if they can help me.  If at any point during the therapy, my therapist assesses that they are ineffective in helping me reach the therapeutic goals or perceive me as non-compliant or non-responsive, they will discuss with me the termination of treatment.  In such a case, if appropriate and/or necessary, they may give a referral that may be of help to me.</p>

    </>
)

export const toastConfigs = {
    position: "bottom-right",
    pauseOnHover: true,
    theme: "light",
    transition: 'bounce',
};